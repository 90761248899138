const config = {
	TIMEZONE: "America/Sao_Paulo",
};

const envData = (ENV, local, test, production) => {
	if (ENV === "local") {
		return local;
	} else if (ENV === "test") {
		return test;
	} else if (ENV === "production") {
		return production;
	} else {
		console.log(new Error("Something went wrong with credentials"));
	}
};

export const ENV_TYPE = "production"; // local // test // production

export const BASE_URL = envData(
	ENV_TYPE,
	"http://localhost:3006/api/v1/",
	"https://testapi.inovajuris.com.br/api/v1/",
	"https://api.inovajuris.com.br/api/v1/"
);

export default config;
